.project-filters {
  @include backdrop--secondary;
  padding: 5rem 0;

  .project-filters-inner {
    @include pulsar-container($oh-container-width-narrow);

    margin-bottom: 4rem;
  }

  label {
    position: relative;
    @include font-size(16px);
    display: grid;
    grid-template-columns: 1.5625em auto;
    gap: 0.375em;

    input {
      left: 0;
    }
  }

  .category-tiles {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .category {
    h3 {
      margin-top: 2em;
      font-family: $font-family-base;
      @include font-size(18px);
      margin-bottom: 1.5em;
    }
  }

  @include media-breakpoint-up(md) {
    .filters-copy {
      width: 100%;
      max-width: 220px;
    }

    .filters {
      width: 100%;
      max-width: 1173px;
      display: flex;
      gap: 40px;
    }

    .category {
      width: 50%;
    }

    .category-tiles {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include media-breakpoint-up(lg) {
    .project-filters-inner {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }

    .category {
      width: auto;
      min-width: 20%;

      h3 {
        margin-bottom: 2em;
        margin-top: 0;
      }
    }

    .category-tiles {
      grid-template-rows: repeat(5, 1fr);
      grid-auto-flow: column;
      grid-auto-columns: 1fr;

      label {
        max-width: 14.375rem;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .category-tiles {
      grid-template-rows: repeat(4, 1fr);
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }
  }
}

.project-listing {
  padding-top: 7rem;
  padding-bottom: 7rem;

  .project-listing-inner {
    @include pulsar-container($oh-container-width-wide);

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem 2rem;
  }

  .project-item {
    max-width: 455px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .project-details {
    margin-bottom: 1.5rem;

    .project-title {
      @include font-size(22.5px);
      font-weight: bold;
      font-family: $font-family-base;
      margin: 0;
      margin-bottom: 0.25em;
    }

    .filter-tags {
      margin: 0;

      .filter-tag {
        width: fit-content;
        @include font-size(14px);
        background-color: #d8d8d8;
        color: white;
        line-height: 1em;
        padding: 0.25em;
        display: inline-block;

        &.filter-tag-industry {
          // TODO: different background?
        }

        &.filter-tag-feature {
          // TODO: different background?
        }
      }
    }
  }

  .project-image {
    position: relative;
    width: 100%;
    margin-top: auto;
    transition: transform 0.3s, box-shadow 0.3s;

    img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: opacity 0.3s;
      box-shadow: 0 2px 16px 0 rgba(33, 33, 33, 0.1);
    }
  }

  .project-overlay {
    margin-top: 1.25rem;

    p {
      margin: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    .project-listing-inner {
      grid-template-columns: repeat(2, 1fr);
      gap: 5.5rem 2rem;
    }

    .project-image {
      background-color: white;
      box-shadow: 0 2px 16px 0 rgba(33, 33, 33, 0.1);

      img {
        box-shadow: none;
      }
    }

    .project-item {
      @include interact {
        .project-image {
          transform: scale(102%);
          box-shadow: 0 2px 28px 0 rgba(100, 102, 122, 0.6);

          img {
            opacity: 10%;
          }
        }

        .project-overlay {
          opacity: 100%;
        }
      }
    }

    .project-overlay {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  @include media-breakpoint-up(lg) {
    .project-listing-inner {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.project-quote {
  overflow: hidden;

  .project-quote-inner {
    @include pulsar-container($oh-container-width-wide);
  }

  .project-contact-form {
    position: relative;
    padding: 1rem;

    &::before {
      content: '';
      position: absolute;
      background-color: $oh-lightest-grey;
      top: 0;
      left: 0;
      width: 200vw;
      height: 100%;
      z-index: -1;
    }
  }

  .project-quote-copy h2 {
    margin-top: 1em;
  }

  @include media-breakpoint-up(md) {
    .project-quote-inner {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 2rem;
    }

    .project-quote-copy {
      grid-column: 1 / span 5;
    }

    .project-contact-form {
      grid-column: span 7;
      padding: 2rem 2rem 2rem 5rem;
    }
  }

  @include media-breakpoint-up(xl) {
    .project-quote-copy {
      grid-column: 2 / span 4;
    }

    .project-contact-form {
      grid-column: span 7;
      padding: 2rem 2rem 2rem 5rem;
    }
  }
}

.project-modal {
  background-color: white;
  position: relative;
  padding: 40px 0;

  .project-title,
  .project-copy .project-copy-inner,
  .project-cta {
    max-width: 1000px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .project-title {
    h3 {
      @extend %oh-h4;
      margin-top: 1rem;
      margin-bottom: 2.5rem;
    }
  }

  .project-image {
    position: relative;

    img {
      margin: auto;
      position: relative;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: $oh-light-grey;
      z-index: 0;
    }
  }

  .project-copy {
    background-color: $oh-light-grey;

    .project-copy-inner {
      max-width: 695px;
      padding-top: 3rem;
      padding-bottom: 4rem;

      *:first-child {
        margin-top: 0;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .project-cta {
    max-width: 695px;
    padding-top: 1rem;
    padding-bottom: 2rem;

    h4 {
      @extend %oh-h3;
    }
  }

  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  @include media-breakpoint-up(md) {
    .project-title,
    .project-image img,
    .project-copy .project-copy-inner,
    .project-cta {
      padding-left: 40px;
      padding-right: 40px;
    }

    .project-title {
      h3 {
        font-size: 3.5625rem;
        margin-top: 2rem;
      }
    }

    .project-cta {
      h4 {
        font-size: 2.8125rem;
      }
    }
  }
}

#project-pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 20px;

  button {
    background: none;
    border: 1px solid transparent;
    font-weight: bold;
    width: 1.875rem;
    height: 1.875rem;
    font-size: 1.125rem;
    transition: all 0.3s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &[disabled] {
      color: $gray-500;
    }

    @include interact {
      border-color: $primary;
    }

    &.active {
      background-color: $primary;
      border-color: $primary;
      color: inherit;
    }

    &.next,
    &.prev {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 0;
      border: none;
      transition: all 0.3s ease-in-out;

      &:not([disabled]):hover {
        color: $primary;
      }
    }

    &.prev:before,
    &.next:before {
      font-size: 1.5rem;
    }

    &.prev:before {
      content: '\f053';
      margin-right: 1.5rem;
    }

    &.next:before {
      content: '\f054';
      margin-left: 1.5rem;
    }
  }
}
