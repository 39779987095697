html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  min-width: 300px;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  -webkit-font-smoothing: antialiased;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// ---- Paragraphs
p {
  margin: 0 0 1em;
  line-height: 1.793em;
}

// creating custom placeholders that act the same as h1 and .h1
// but avoid problems with extending classes

%oh-h1 {
  @extend %heading;
  @include font-size($h1-font-size);
}

%oh-h2 {
  @extend %heading;
  @include font-size($h2-font-size);
}

%oh-h3 {
  @extend %heading;
  @include font-size($h3-font-size);
}

%oh-h4 {
  @extend %heading;
  @include font-size($h4-font-size);
}

%oh-h5 {
  @extend %heading;
  @include font-size($h5-font-size);
}

%oh-h6 {
  @extend %heading;
  @include font-size($h6-font-size);
}

// custom heading styles that apply to all headings
%oh-heading {
  letter-spacing: -0.025em;

  span.alt-font {
    font-family: $font-family-base;
    font-weight: 700;
    &::before {
      content: '\a';
      white-space: pre;
    }
  }

  a {
    text-decoration: none;
  }
}

// custom heading styles for specific headings

h1,
.h1,
%oh-h1 {
  @extend %oh-heading;

  margin-top: 1em;

  // NOTE: this is avoiding the font-size mixin on purpose
  font-size: $h1-font-size;

  @include media-breakpoint-down(ss) {
    font-size: 3.2rem;
  }
}

h2,
.h2,
%oh-h2 {
  @extend %oh-heading;

  // NOTE: this is avoiding the font-size mixin on purpose
  font-size: $h2-font-size;
}

h3,
.h3,
%oh-h3 {
  @extend %oh-heading;
}

h4,
.h4,
%oh-h4 {
  @extend %oh-heading;
}

h5,
.h5,
%oh-h5 {
  @extend %oh-heading;
}

h6,
.h6,
%oh-h6 {
  @extend %oh-heading;
}

// ---- Links
a {
  font-size: inherit;
  transition: color 0.3s ease-in-out;
  text-decoration: underline;
  text-decoration-color: $primary;

  @include interact {
    color: lighten($primary, 10%);
    text-decoration-color: lighten($primary, 10%);
  }

  &.unstyled-link {
    @include unstyled-link;
  }

  // ----- Body Links: <IMG>
  img {
    border: 0;
  }

  // ----- Body Links: Tel Links
  &[href^='tel'] {
    @include unstyled-link;
  }
}

// ---- Lists
ul,
ol {
  margin-bottom: 1.5em;
  padding-left: 1.75em;
}

// ---- Blockquote
blockquote {
  border-left: 2px solid $primary;
  margin: 3em 0;
  padding: 0 1.5rem;
  max-width: 75%;
}

// ---- Pagination
.pagination {
  text-align: center;
  padding: 3em 1em;

  ul {
    @include list-unstyled;

    // ----- UL > LI
    li {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0 3px;
      background-color: white;
      border: 1px solid $oh-lightest-grey;

      a {
        display: block;
        padding: 5px 10px;
        margin: 0;
        text-align: center;
        text-decoration: none;
      }

      @include interact {
        background-color: lighten($primary, 15%);
        border-color: lighten($primary, 15%);

        a {
          color: white;
        }
      }

      // ----- UL > LI.Active
      &.active {
        background-color: $primary;
        border-color: $primary;

        a {
          font-weight: bold;
          color: white;
        }
      }

      // ----- UL > LI.Disabled
      &.disabled {
        background-color: $oh-lightest-grey !important;
        border-color: $oh-lightest-grey !important;

        a {
          color: $oh-light-grey !important;
          cursor: not-allowed !important;
        }
      }
    }
  }
}

// ---- Breadcrumbs
.breadcrumbs {
  @include list-unstyled;
  color: $oh-light-grey;

  li {
    display: inline-block;
  }

  a:not(:hover) {
    color: $oh-light-grey;
    text-decoration: none;
  }
}

/* ----- Body Copy: Table */
table {
  @extend .table;
  @extend .table-bordered;
  @extend .table-striped;

  th {
    font-family: $headings-font-family;
  }
}

// Buttons
.btn {
  &.btn-primary {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.btn-outline-primary {
    color: $body-color;
  }

  &.btn-secondary {
    &:hover,
    &:focus {
      background-color: lighten($secondary, 15%);
      border-color: lighten($secondary, 15%);
    }
  }

  &.btn-light {
    color: $body-color;
    background-color: #ffffff;
    border-color: #ffffff;

    @include interact {
      color: $body-color;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.btn-arrow,
  &.btn-arrow-long {
    display: flex;
    align-items: center;
    width: fit-content;

    &::after {
      content: '';
      display: block;
      width: 22px;
      height: 17px;
      background-image: url('/assets/frontend/images/icons/button-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-left: 8px;
    }

    &:empty {
      &::after {
        margin-left: 0px;
      }
    }
  }

  &.btn-arrow-long {
    &::after {
      width: 42px;
      background-image: url('/assets/frontend/images/icons/button-arrow-long.svg');
      margin-left: 16px;
    }
  }
}

.link-arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: 700;
  text-decoration: none;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    height: 1px;
    width: calc(100% - 63px);
    background-color: $primary;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.3s ease-in-out;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 53px);
    }
  }

  @include interact {
    color: inherit;

    &:before {
      transform: scaleX(1);
    }
  }

  &::after {
    content: '';
    @extend .btn;
    @extend .btn-primary;
    display: block;
    width: 38px;
    height: 38px;
    padding: 14px 10px;
    background-image: url('/assets/frontend/images/icons/button-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22.5px 16px;
    margin: auto;
    margin-left: 25px;
  }

  &:empty {
    &::after {
      margin-left: 0px;
    }
  }

  @include media-breakpoint-down(sm) {
    &::before {
      width: calc(100% - 52px);
    }

    &::after {
      margin-left: 15px;
    }
  }
}

nav.main-nav {
  ul {
    @include list-unstyled;
    margin: 0;
  }

  > ul {
    display: flex;
    gap: 1rem;

    > li {
      @include font-size(17px);
      font-weight: 800;
      text-transform: uppercase;

      a {
        padding: 0.25em 0.25em;
        position: relative;
        text-decoration: none;
        color: $body-color;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $primary;
          top: 53%;
          left: 50%;
          transform: translate(-50%, -50%) scaleX(0);
          transform-origin: left;
          transition: all 0.3s ease-in-out;
        }

        @include interact {
          &:after {
            opacity: 1;
            transform: translate(-50%, -50%) scaleX(1);
          }
        }

        &.active {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

html {
  &.nav-open {
    overflow: hidden;

    body {
      overflow: hidden;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.accordion {
  .accordion-item {
    padding-bottom: 1rem;
    padding-left: 6.5rem;
    border: 1px solid $secondary;
  }

  .accordion-header {
    all: unset;
  }

  .accordion-button {
    all: unset;
    cursor: pointer;
    position: relative;
    padding: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    margin-left: -0.5rem;
    @include font-size(18px);
    font-family: $font-family-base;
    font-weight: normal;
    transition: all 0.3s ease-in-out;

    &:before {
      //icon for closed accordion
      content: '\f078';
      position: absolute;
      @include font-size(20px);
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      top: 50%;
      left: -3rem;
      transform: translate(-50%, -50%);
    }

    @include interact {
      color: $primary;
    }

    &:focus {
      color: $primary;
      box-shadow: 0 0 0 0.25rem rgba($primary, 0.5);
    }

    &[aria-expanded='true'] {
      &:before {
        //icon for opened accordion
        content: '\f077';
      }
    }
  }

  .accordion-body {
    *:first-child {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }

    p {
      @include font-size(16px);
    }
  }
}

@mixin backdrop--secondary {
  background-color: $secondary;
  color: white;

  * {
    color: white;
  }

  .bg-text-decoration {
    > * {
      color: white;
      -webkit-text-stroke-color: white;
    }
  }

  a {
    color: $primary;
    transition: all 0.3s ease-in-out;

    &.btn {
      color: $oh-dark-grey;
    }

    @include interact {
      color: lighten($primary, 10%);
      text-decoration-color: lighten($primary, 10%);
    }
  }

  .accordion {
    .accordion-item {
      border-color: white;
    }

    .accordion-button {
      &:before {
        color: $primary;
      }
    }
  }

  // Custom checkbox styles for backdrop section
  input[type='checkbox'] {
    &:before {
      border: 1px solid white;
    }

    &:not(:checked, :hover) {
      &:before {
        background-color: $secondary;
      }
    }
  }
}

ul.dash-style {
  padding-left: 0.5em;

  li {
    list-style-type: '-';
    padding-inline-start: 1rem;

    &::marker {
      color: $primary;
    }
  }
}

label {
  @include font-size(18px);
}

// Custom checkbox styles
input[type='checkbox'] {
  position: relative;
  appearance: none;
  cursor: pointer;
  margin: 0;
  margin-right: 0.5em;
  width: 1.5em !important;
  height: 1.5em !important;
  vertical-align: middle;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid $secondary;
    border-radius: 0;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 38%;
    top: 15%;
    width: 0.4em;
    height: 0.8em;
    border: solid $secondary;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @include interact {
    &:before {
      background-color: $primary;
    }
  }

  &:checked:before {
    background-color: $primary;
  }

  &:checked:after {
    display: block;
  }

  &:focus {
    outline-offset: 3px;
    outline-color: $primary;
  }
}

.bg-text-decoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;

  .marquee {
    width: 100% !important;

    * {
      line-height: 0.85em;
    }

    &:nth-of-type(2) {
      transform: rotateY(180deg);

      [data-infinite-marquee] {
        transform: rotateY(180deg);
      }
    }
  }

  > * {
    @include font-size(131.25px);
    margin: 0;
    font-family: $font-family-base;
    line-height: 1em;
    font-weight: 800;
    letter-spacing: -0.025em;
    color: $secondary;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $secondary;
    text-transform: uppercase;
    opacity: 0.07;

    &:after {
      content: '\00a0';
    }

    @media (max-width: 480px) {
      font-size: 64px;
    }
  }

  [data-infinite-marquee] {
    &::after {
      content: '\00a0';
    }
  }
}

body.acsb-keynav {
  a,
  .btn,
  .accordion-button {
    &:focus {
      outline: none !important;
    }
  }

  .slider-item {
    &:focus {
      border: 1px solid $primary;
      border-radius: 4px;
    }
  }

  .site-logo {
    a {
      &:focus {
        img {
          border: solid 1px $primary;
          border-radius: 12px;
        }
      }
    }
  }
}

.acsb-trigger {
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px, rgb(0 0 0 / 20%) 0px 2px 12px !important;

  &.acsb-trigger-size-medium,
  &.acsb-trigger-size-large {
    width: 60px !important;
    height: 60px !important;
  }
}

html.nav-open {
  #hubspot-messages-iframe-container,
  .acsb-trigger {
    display: none !important;
  }
}
