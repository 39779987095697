/**
 * Here are some variables you should be aware of, but not have to edit:
 *
 *   $font-weight-lighter;
 *   $font-weight-light;
 *   $font-weight-normal;
 *   $font-weight-semibold;
 *   $font-weight-bold;
 *   $font-weight-bolder;
 *
 *   $border-radius;
 *   $border-radius-sm;
 *   $border-radius-lg;
 *   $border-radius-xl;
 *   $border-radius-2xl;
 *   $border-radius-pill;
 *
 *   $box-shadow;
 *   $box-shadow-sm;
 *   $box-shadow-lg;
 *   $box-shadow-inset;
 *
 *   $zindex-dropdown;
 *   $zindex-sticky;
 *   $zindex-fixed;
 *   $zindex-offcanvas-backdrop;
 *   $zindex-offcanvas;
 *   $zindex-modal-backdrop;
 *   $zindex-modal;
 *   $zindex-popover;
 *   $zindex-tooltip;
 *   $zindex-toast;
 */

/**
 * Override the following as needed. Ignore otherwise.
 * They are all commented out with their default values.
 */

/************ COLORS ************/

//$white:      #fff;
$gray-100: $oh-lightest-grey;
$gray-200: $oh-light-grey;
//$gray-300:   #dee2e6;
//$gray-400:   #ced4da;
//$gray-500:   #adb5bd;
//$gray-600:   #6c757d;
$gray-700: $oh-medium-grey;
//$gray-800:   #343a40;
$gray-900: $oh-dark-grey;
//$black:      #000;

//$blue:       #0d6efd;
//$indigo:     #6610f2;
//$purple:     #6f42c1;
//$pink:       #d63384;
//$red:        #dc3545;
//$orange:     #fd7e14;
//$yellow:     #ffc107;
//$green:      #198754;
//$teal:       #20c997;
//$cyan:       #0dcaf0;

$primary: $oh-orange;
$secondary: $oh-dark-grey;

//$body-bg:    $white;
//$body-color: $oh-dark-grey;

/************ LINKS ************/

$link-color: $oh-dark-grey;
//$link-decoration:       underline;
//$link-hover-decoration: null;

/************SPACING ************/

//$spacer:                  1rem;

//$paragraph-margin-bottom: 1rem;

$grid-gutter-width: 2.5rem;

//$container-padding-x:     $grid-gutter-width;

/************ FONTS ************/

$font-family-base: 'graphie', Arial, Verdana, sans-serif;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
//$font-size-root:              null;
$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`

$font-weight-base: 500;
//$line-height-base:            1.5;

$h1-font-size: 3.5625rem;
$h2-font-size: 2.8125rem;
$h3-font-size: 2.1875rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.40625rem;
$h6-font-size: 1.125rem;

$headings-margin-bottom: 0.5em;
$headings-font-family: 'bennet-banner', Arial, Verdana, sans-serif;
//$headings-font-style:         null;
$headings-font-weight: 700;
$headings-line-height: normal;
//$headings-color:              null;

//$small-font-size:             .875em;

//$sub-sup-font-size:           .75em;

/************ BUTTONS ************/

/**
 * Don't try to edit the $input-btn- or $border-radius variables.
 * Just override the values of the $btn- variables.
 */

$btn-color: $secondary;
$btn-padding-y: 0.5em;
$btn-padding-x: 0.8em;
//$btn-font-family:             $input-btn-font-family;
$btn-font-size: 18px;
// $btn-line-height:             $input-btn-line-height;
//$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

//$btn-padding-y-sm:            $input-btn-padding-y-sm;
//$btn-padding-x-sm:            $input-btn-padding-x-sm;
//$btn-font-size-sm:            $input-btn-font-size-sm;

//$btn-padding-y-lg:            $input-btn-padding-y-lg;
//$btn-padding-x-lg:            $input-btn-padding-x-lg;
//$btn-font-size-lg:            $input-btn-font-size-lg;

//$btn-border-width:            $input-btn-border-width;

$btn-font-weight: 700;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
//$btn-focus-width:             $input-btn-focus-width;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);\

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0;
//$btn-border-radius-sm:        $border-radius-sm;
//$btn-border-radius-lg:        $border-radius-lg;

// scss-docs-start modal-variables
// $modal-inner-padding:               $spacer;

// $modal-footer-margin-between:       .5rem;

// $modal-dialog-margin:               .5rem;
// $modal-dialog-margin-y-sm-up:       1.75rem;

// $modal-title-line-height:           $line-height-base;

// $modal-content-color:               null;
// $modal-content-bg:                  $white;
// $modal-content-border-color:        var(--#{$prefix}border-color-translucent);
// $modal-content-border-width:        $border-width;
$modal-content-border-radius: 0;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
// $modal-content-box-shadow-xs:       $box-shadow-sm;
// $modal-content-box-shadow-sm-up:    $box-shadow;

// $modal-backdrop-bg:                 $black;
$modal-backdrop-opacity: 0.9;

// $modal-header-border-color:         var(--#{$prefix}border-color);
// $modal-header-border-width:         $modal-content-border-width;
// $modal-header-padding-y:            $modal-inner-padding;
// $modal-header-padding-x:            $modal-inner-padding;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

// $modal-footer-bg:                   null;
// $modal-footer-border-color:         $modal-header-border-color;
// $modal-footer-border-width:         $modal-header-border-width;

// $modal-sm:                          300px;
// $modal-md:                          500px;
// $modal-lg:                          800px;
// $modal-xl:                          1140px;

// $modal-fade-transform:              translate(0, -50px);
// $modal-show-transform:              none;
// $modal-transition:                  transform .3s ease-out;
// $modal-scale-transform:             scale(1.02);
// scss-docs-end modal-variables

// scss-docs-start carousel-variables
$carousel-control-color: $primary;
// $carousel-control-width:             15%;
// $carousel-control-opacity:           .5;
// $carousel-control-hover-opacity:     .9;
// $carousel-control-transition:        opacity .15s ease;

// $carousel-indicator-width:           30px;
// $carousel-indicator-height:          3px;
// $carousel-indicator-hit-area-height: 10px;
// $carousel-indicator-spacer:          3px;
// $carousel-indicator-opacity:         .5;
$carousel-indicator-active-bg: $primary;
// $carousel-indicator-active-opacity:  1;
// $carousel-indicator-transition:      opacity .6s ease;

// $carousel-caption-width:             70%;
// $carousel-caption-color:             $white;
// $carousel-caption-padding-y:         1.25rem;
// $carousel-caption-spacer:            1.25rem;

// $carousel-control-icon-width:        2rem;

// $carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
// $carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

// $carousel-transition-duration:       .6s;
// $carousel-transition:                transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// scss-docs-end carousel-variables

// scss-docs-start carousel-dark-variables
// $carousel-dark-indicator-active-bg:  $black;
// $carousel-dark-caption-color:        $black;
// $carousel-dark-control-icon-filter:  invert(1) grayscale(100);
// scss-docs-end carousel-dark-variables

// scss-docs-start table-variables
// $table-cell-padding-y:        .5rem;
// $table-cell-padding-x:        .5rem;
// $table-cell-padding-y-sm:     .25rem;
// $table-cell-padding-x-sm:     .25rem;

// $table-cell-vertical-align:   top;

// $table-color:                 var(--#{$prefix}body-color);
// $table-bg:                    transparent;
// $table-accent-bg:             transparent;

// $table-th-font-weight:        null;

// $table-striped-color:         $table-color;
// $table-striped-bg-factor:     .05;
// $table-striped-bg:            rgba($black, $table-striped-bg-factor);

// $table-active-color:          $table-color;
// $table-active-bg-factor:      .1;
// $table-active-bg:             rgba($black, $table-active-bg-factor);

// $table-hover-color:           $table-color;
// $table-hover-bg-factor:       .075;
// $table-hover-bg:              rgba($black, $table-hover-bg-factor);

// $table-border-factor:         .1;
$table-border-width: 2px;
$table-border-color: transparent;

// $table-striped-order:         odd;
// $table-striped-columns-order: even;

// $table-group-separator-color: currentcolor;

// $table-caption-color:         $text-muted;

// $table-bg-scale:              -80%;
// scss-docs-end table-variables

// ----- Global Breakpoints
$grid-breakpoints: (
  xs: 0,
  ss: 390px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$container-max-widths: (
  md: 750px,
  lg: 970px,
  xl: 1170px,
  xxl: 1320px,
);
