.feature-banner.feature-banner-home {
  @include oh-feature-banner-common;
}

.homepage-stats {
  position: relative;
  @include backdrop--secondary;
  padding: 12rem 0;

  @include media-breakpoint-down(sm) {
    padding: calc(5.125rem + 22vw) 0 3rem;
  }

  .bg-text-decoration {
    top: 4rem;
  }

  .homepage-stats-inner {
    position: relative;
    @include pulsar-container($oh-container-width-narrow);

    .homepage-stats-copy {
      a {
        font-weight: 600;
      }
    }

    .homepage-stats-tiles {
      .stats-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.222rem;
      }

      .homepage-stats-cert {
        margin-top: 3rem;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }

      .stats-item {
        span {
          @include font-size(57px);
          line-height: 1em;
          font-family: $headings-font-family;
          font-weight: bold;

          &::after {
            content: '\a';
            white-space: pre;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: clamp(96px + 2rem, 32vw + 2rem, 192px + 2rem) 0 6rem;
    .homepage-stats-inner {
      .homepage-stats-tiles {
        .stats-grid {
          grid-template-columns: 1fr;
          padding: 4.5rem 0rem 2rem;
          gap: 1.8rem;
          margin: auto;
          max-width: 226px;
        }

        .homepage-stats-cert {
          max-width: 226px;
          margin: auto;
        }

        .stats-item {
          span {
            @include media-breakpoint-down(sm) {
              font-size: 3.5625rem;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .homepage-stats-inner {
      display: flex;
      justify-content: space-between;
      gap: 3rem;

      .homepage-stats-copy {
        max-width: 563px;
      }

      .homepage-stats-tiles {
        max-width: 650px;
      }
    }
  }
}

.our-services {
  position: relative;
  padding: 43px 0;
  overflow: hidden;

  .bg-text-decoration {
    top: clamp(14rem, 72vw, 24rem);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    white-space: nowrap;
    z-index: -1;
  }

  .our-services-inner {
    position: relative;
    @include pulsar-container($oh-container-width-narrow);
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;
    row-gap: 0px;
  }

  .photo-collage {
    display: grid;
    grid-auto-rows: min-content;
    gap: 18px;
    align-items: stretch;

    img {
      width: 100%;
      max-height: 390px;
      object-fit: cover;
    }
  }

  .services-copy {
    *:first-child {
      margin-top: 0;
    }
  }

  .services-quicklinks {
    padding-top: 3rem;
    padding-bottom: 2rem;
    p {
      @include font-size(22.5px);
      line-height: normal;
      font-weight: bold;
      margin-bottom: 2em;
      padding-bottom: 0.25em;
      width: fit-content;

      a {
        position: relative;
        text-decoration: none;

        &::before {
          content: '';
          position: absolute;
          bottom: -0.25em;
          height: 1px;
          width: 100%;
          background-color: $primary;
          transform-origin: left;
          transition: all 0.3s ease-in-out;
        }

        @include interact {
          color: $primary;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .bg-text-decoration {
      top: unset;
      bottom: 4rem;
      left: 0;
      transform: none;

      .dynamic-linebreak {
        &:before {
          content: '\a';
          white-space: pre;
        }
      }
    }

    .our-services-inner {
      grid-template-columns: repeat(10, 1fr);

      .photo-collage {
        grid-column: span 3;
      }

      .services-copy {
        margin: auto;
      }

      .services-copy,
      .services-links {
        grid-column: 4 / span 4;
      }

      .services-quicklinks {
        grid-column: 8 / span 3;
      }
    }

    .photo-collage {
      grid-template-columns: 1fr 1.25fr;
      gap: 18px;

      img {
        &:nth-child(1) {
          grid-column: span 2;
        }

        &:nth-child(3) {
          grid-row: span 2;
        }
      }
    }

    .services-quicklinks {
      padding-top: 100px;
    }
  }

  @include media-breakpoint-up(lg) {
    .bg-text-decoration {
      left: -11rem;
    }
  }

  @include media-breakpoint-up(xxl) {
    .our-services-inner {
      .photo-collage {
        grid-column: span 3;
      }

      .services-copy {
        margin: 0;
      }

      .services-copy,
      .services-links {
        grid-column: 5 / span 3;
      }

      .services-quicklinks {
        grid-column: 9 / span 2;
      }
    }

    .services-copy {
      padding-top: 175px;
    }

    .services-quicklinks {
      padding-top: 275px;
    }
  }

  @include media-breakpoint-down(md) {
    .photo-collage {
      display: block;
      padding-top: 0.5rem;
      padding-bottom: 7.6rem;
      max-width: 450px;
      width: 85%;

      img {
        &:first-child {
          margin-left: calc(0px - $oh-container-padding-default);
        }
        &:not(&:first-child) {
          display: none;
        }
      }
    }
  }
}

.logo-carousel {
  position: relative;
  margin-bottom: 6rem;

  .bg-text-decoration {
    left: 0;
  }

  &.about-us {
    margin-bottom: 0px;

    @include media-breakpoint-down(md) {
      padding: 3rem 0;

      p {
        display: none;
      }
    }

    .logo-carousel-copy {
      @include media-breakpoint-down(md) {
        padding: 5.25rem 0rem;
      }

      &::after {
        right: 40%;
      }
    }
  }

  .logo-carousel-inner {
    position: relative;
    @include pulsar-container($oh-container-width-wide);

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
  }

  .logo-carousel-copy {
    position: relative;
    display: flex;
    align-items: center;

    .logo-carousel-copy-inner {
      max-width: 412px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
      height: 100%;
      background-color: $oh-light-grey;
      z-index: -1;
    }

    @include media-breakpoint-down(md) {
      padding-top: 5.25rem;
      padding-bottom: 3rem;

      .logo-carousel-copy-inner {
        h2 {
          margin-bottom: 4.625rem;
        }
      }
    }
  }

  .carousel {
    display: flex;
    align-items: center;
  }

  .carousel-tiles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    gap: 4rem 2rem;
    padding: 1rem;

    .slider-item {
      display: flex;
      justify-content: center;
      align-items: center;
      filter: grayscale(1);
      transition: all 0.3s ease-in-out;
      aspect-ratio: 1/1;

      img {
        max-height: 100%;
        object-fit: contain;
      }

      @include interact {
        filter: grayscale(0);
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.about-us {
      padding: 3rem 0;

      p {
        display: none;
      }
    }

    .trusted-companies-copy {
      padding: 5.25rem 0rem;

      &::after {
        right: 50%;
      }
    }

    .trusted-companies-copy {
      padding-top: 5.25rem;
      padding-bottom: 3rem;

      .trusted-companies-copy-inner {
        h2 {
          margin-bottom: 4.625rem;
        }
      }
    }

    .trusted-companies-slider {
      gap: 4rem 2rem;
    }

    .carousel-tiles {
      gap: 2rem 2rem;

      .slider-item {
        aspect-ratio: 2/1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .bg-text-decoration {
      left: -3rem;

      &.about-us {
        .bg-text-decoration {
          top: 7.5rem;
        }
      }
    }

    .carousel-tiles {
      gap: 4rem;
    }

    .logo-carousel-inner {
      grid-template-columns: repeat(12, 1fr);
      min-height: 788px;
    }

    .logo-carousel-copy {
      grid-column: span 7;
      padding-right: 1rem;

      &::after {
        top: 0;
        left: unset;
        right: 0;
        width: 100vw;
      }
    }

    .carousel {
      grid-column: span 5;
    }

    &.about-us {
      .logo-carousel-inner {
        min-height: 650px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .logo-carousel-inner {
      grid-template-columns: repeat(12, 1fr);
    }

    .logo-carousel-copy {
      grid-column: 1 / span 6;
      justify-content: center;
    }

    .carousel {
      grid-column: span 6;
    }

    .carousel-tiles {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include media-breakpoint-up(xxxl) {
    .logo-carousel-copy {
      grid-column: 2 / span 4;
    }

    .carousel {
      grid-column: span 7;
    }
  }
}

.lets-build {
  position: relative;
  overflow: hidden;

  .lets-build-inner {
    @include pulsar-container($oh-container-width-wide);
  }

  .photo-collage {
    display: grid;
    grid-template-columns: 1fr 1fr 0.75fr 0.5fr;
    gap: 11px;
    width: 100%;
    max-width: 570px;
    margin-bottom: 3.5rem;
    align-items: stretch;

    img {
      width: 100%;
      object-fit: cover;

      &:nth-child(1) {
        grid-row: span 2;
      }

      &:nth-child(2) {
        grid-column: 2 / span 2;
      }

      &:nth-child(3) {
        grid-column: 1;
      }

      &:nth-child(4) {
        grid-column: 2 / span 3;
        grid-row: 2 / span 3;
      }
    }
  }

  .lets-build-copy {
    padding-bottom: 6rem;
  }

  @include media-breakpoint-up(sm) {
    .photo-collage {
      gap: 18px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .photo-collage {
      grid-template-columns: 1fr 1fr;

      img {
        &:nth-child(1) {
          grid-row: span 2;
        }

        &:nth-child(2) {
          grid-column: 2 / span 1;
        }

        &:nth-child(3) {
          grid-column: 1;
        }

        &:nth-child(4) {
          grid-column: 2 / span 1;
          grid-row: 2 / span 3;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .lets-build-inner {
      display: flex;
    }

    .lets-build-copy {
      max-width: 1022px;
      padding-left: clamp(40px, 10vw, 190px);

      .copy-background {
        padding-top: 4.5rem;
        padding-bottom: 3.75rem;
        margin: 2rem 0rem;
        position: relative;

        p {
          margin-bottom: 0px;
          max-width: 481px;
        }

        &:after {
          position: absolute;
          content: '';
          width: 100vw;
          top: 0;
          left: calc(0px - clamp(32px, 7vw, 105px));
          height: 100%;
          background-color: $oh-light-grey;
          z-index: -1;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .photo-collage {
      margin: auto;
    }

    .lets-build-copy {
      padding-bottom: 4rem;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100vw;
        height: 75%;
        bottom: 0;
        background-color: $oh-light-grey;
        transform: translateX(calc(0px - $oh-container-padding-default));
        z-index: -1;
      }
    }

    .lets-build-quicklinks {
      margin-top: 3rem;
    }
  }
}

.home-faqs {
  position: relative;
  @include backdrop--secondary;
  padding: 7rem 0 8rem;
  overflow: hidden;

  .bg-text-decoration {
    top: -2rem;
  }

  .home-faqs-inner {
    position: relative;
    @include pulsar-container($oh-container-width-wide);
  }

  .faqs-copy {
    position: relative;
    margin-bottom: 4rem;

    h2 {
      margin: 0;
    }
  }

  .faqs {
    position: relative;
    width: 100%;
  }

  //Tweak to the accordions to make the lines look like they reach the edge of the screen
  .accordion-item {
    position: relative;
    border-top: none;
    border-bottom: none;
    border-right: none;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100vw;
      background-color: white;
      left: 0;
    }

    &:first-of-type {
      &::before {
        bottom: 100%;
      }
    }

    &::after {
      top: 100%;
    }

    //limiting the max width of the copy inside of the faqs
    .accordion-header,
    .accordion-body {
      max-width: 860px;
    }
  }

  @include media-breakpoint-up(lg) {
    .bg-text-decoration {
      top: -3.45rem;
      left: -3rem;
    }

    .home-faqs-inner {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }

    .faqs-copy {
      flex: 1 0 auto;
    }

    .faqs {
      max-width: 1325px;
    }
  }
}
