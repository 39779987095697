.contact-header {
  display: grid;
  grid-template-columns: auto clamp(520px, 41%, 800px);

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
  }

  .get-in-touch {
    width: 95%;
    max-width: 665px;
    padding-left: 15px;
    margin-right: 5.57vw;
    margin-left: auto;
    margin-bottom: 1.5rem;

    p.chat-links {
      strong {
        font-weight: 600;
      }

      a[href^='tel']:not(:hover) {
        text-decoration: underline;
      }
    }

    p.start-chat {
      margin: 1.8em 0;
    }

    @include media-breakpoint-down(lg) {
      margin-left: 1rem;
      padding-left: 0px;
      padding-right: calc($grid-gutter-width/2);
    }

    @include media-breakpoint-down(md) {
      h1 {
        font-size: 3.5625rem;
        margin: 0.25em 0;
      }
      p {
        font-size: 1.125rem;
      }
    }
  }

  .contact-form-bg {
    position: absolute;
    background-color: $oh-light-grey;
    min-height: 460px;
    width: clamp(520px, 41%, 800px);
    right: 0px;
    top: 54px;
    z-index: -1;

    @include media-breakpoint-down(xl) {
      top: 23px;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .contact-gap {
    @extend %oh-h1;
    margin-bottom: 0px;
  }

  .contact-form {
    background-color: $oh-light-grey;
    padding-left: 19.3%;
    padding-left: clamp(30px, 19.3%, 152px);
    padding-right: 8.9%;
    padding-right: clamp(15px, 8.9%, 70px);
    padding-bottom: 90px;
    margin-bottom: -333px;
    z-index: 2;

    @include media-breakpoint-down(lg) {
      margin-bottom: -99px;
      width: calc(100% - 1rem);
      max-width: 630px;
      margin-left: auto;
      padding-left: 16px;
      padding-right: calc($grid-gutter-width/2);
    }

    .contact-form-inner {
      width: 100%;
      max-width: 567px;
      margin-right: auto;
    }
  }
}

.contact-us-pin {
  img {
    width: 100%;
    min-height: 727px;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-down(md) {
      object-position: 48%;
    }
  }
}
