.process-steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 95%;
  max-width: 1095px;
  margin: auto;
  padding: 80px 0px 100px;
  padding: clamp(30px, 5.6vw, 76px) 0px clamp(42px, 7.2vw, 96px);

  .process-step {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 316px;

    &:nth-child(odd) {
      background-color: $oh-lightest-grey;
    }

    .process-step-inner {
      margin: auto;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      width: calc(100% - 64px);

      @include media-breakpoint-down(md) {
        width: calc(100% - 32px);
      }

      .process-step-order {
        color: $primary;
        font-size: 2.1875rem;
        letter-spacing: -0.8px;
        font-weight: 700;
        line-height: 1.295;
        margin-bottom: 6px;
      }

      .process-title {
        font-size: 1.75rem;
        font-family: $headings-font-family;
        line-height: 1.25em;
        font-weight: 700;
        margin-bottom: 14px;
      }

      .process-discription {
        * {
          font-size: 1rem;
          line-height: 1.65em;
          color: $oh-dark-grey;
        }

        li {
          margin-bottom: 8px;
        }
      }
    }
  }

  @include media-breakpoint-between(sm, xxl) {
    grid-template-columns: 1fr 1fr;
    max-width: 912px;

    .process-step {
      &:nth-child(odd) {
        background-color: $white;
      }
      &:nth-child(4n + 1) {
        background-color: $oh-lightest-grey;
      }
      &:nth-child(4n) {
        background-color: $oh-lightest-grey;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    max-width: 456px;
    grid-template-rows: none;
  }
}

section.process-quote {
  @include backdrop--secondary;
  margin-top: calc(95.4px + 2.5vw);
  padding-top: calc(20px + 0.5vw);
  padding-bottom: calc(63px + 3vw);
  display: flex;

  .process-quote-inner {
    @include pulsar-container($oh-container-width-wide);
    display: flex;

    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
    }

    .process-quote-inner-image {
      margin-top: calc(-95.4px - 3vw);
      margin-right: 18px;
      width: 100%;
      max-width: 760px;

      @include media-breakpoint-up(xl) {
        margin-left: auto;
      }

      @include media-breakpoint-down(sm) {
        margin-left: calc(0px - $oh-container-padding-default);
      }
    }

    .testimonial-container {
      text-align: right;
      margin-bottom: 45px;
      padding-top: 65px;
      width: 40%;
      margin-right: 0px;

      @include media-breakpoint-down(xxl) {
        max-width: 700px;
        width: 90%;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 40px;
      }

      .process-quote-staff {
        > img {
          display: none;
        }
        br {
          display: none;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: left;
        width: 90%;
        margin-left: calc($grid-gutter-width/2);
        margin-right: auto;
        margin-bottom: 0px;

        > img {
          display: none;
        }

        .process-quote-staff {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > img {
            display: block;
            margin-right: 16px;
            max-width: 111px;
          }

          br {
            display: block;
          }

          .process-quote-staff-info {
            max-width: 220px;
          }
        }
      }

      > img {
        margin-right: 0px;
        margin-left: 35px;
      }

      .testimonial-container-text {
        .quote-content {
          @extend %oh-h4;
          margin-top: 1em;
          color: $white;
          &::before {
            right: 0%;
          }
        }
      }
    }
  }
}
