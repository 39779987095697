// -------- Pulsar Container
$oh-bootstrap-container-default: map-get($container-max-widths, 'xxl') - 40px;

@function remtopx($rem) {
  @return calc($rem / 1rem) * 16px;
}

@mixin pulsar-container(
  $container-width-origin: $oh-bootstrap-container-default,
  $container-padding: remtopx($container-padding-x)
) {
  @include make-container($container-padding);
  $container-width: $container-width-origin + $container-padding;
  max-width: $container-width;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      @if $container-width > $container-max-width {
        max-width: $container-max-width;
      } @else {
        max-width: $container-width;
      }
    }

    @include media-breakpoint-up(xxl) {
      max-width: $container-width;
    }
  }
}

//-------------------
@mixin interact {
  &:hover,
  &.hover,
  &:active,
  &.active,
  &.focus,
  &:focus {
    @content;
  }
}

@mixin unstyled-link {
  &:not(:hover) {
    text-decoration: none;
    color: inherit;
  }
}

@mixin oh-feature-banner-common {
  .feature-banner-inner {
    @include pulsar-container(790px);
    margin-bottom: 12rem;

    @include media-breakpoint-down(xl) {
      @include pulsar-container(970px);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 3.5rem;
    }

    > p {
      max-width: 490px;
    }
  }

  .cta-dropdown {
    @include pulsar-container(696px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 auto;

    .btn {
      padding: 0.7em 1.4em;
    }

    .fas {
      margin-left: 5px;
      transform: translateY(2px);
    }

    @include media-breakpoint-between(md, xxl) {
      .btn {
        margin-right: 40px;
      }
    }

    @include media-breakpoint-down(xl) {
      justify-content: left;
      @include pulsar-container(970px);
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }

    p {
      font-weight: bold;
      margin: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    .feature-banner-inner {
      > p {
        max-width: 581px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: clamp(108px + 3rem, 36vw + 3rem, 216px + 3rem);
  }
}
