$teamVerticalOffset: 15rem;
$teamTopSpacing: 17rem;

.team-section {
  position: relative;

  .team-section-inner {
    @include pulsar-container(940px);
    padding-top: 10rem;
    padding-top: clamp(4rem, 10vw, 10rem);
    padding-bottom: 10rem;
    padding-bottom: clamp(2rem, 6vw, 6rem);

    .team-listing,
    .team-text {
      display: grid;
      gap: 4rem;
      gap: clamp(3rem, 4vw, 4rem);
      grid-template-columns: 1fr;

      h3 {
        margin: 1em 0 0 0;
        @extend %oh-h4;
      }

      p {
        margin: 0.25em 0;
        width: 100%;
      }

      img {
        width: 100%;
        filter: grayscale(1);
      }
    }

    @include media-breakpoint-up(sm) {
      .team-listing,
      .team-text {
        grid-template-columns: 1fr 1fr;
        margin-top: $teamTopSpacing;

        > div {
          padding: 0 0 6rem 0;

          img {
            max-width: 332px;
          }

          p {
            max-width: 452px;
          }
        }
      }

      .team-listing {
        > div {
          &:nth-of-type(4n + 4) {
            justify-self: end;
          }

          &:nth-child(odd) {
            margin-top: -$teamVerticalOffset;
          }

          &:nth-child(even) {
            align-self: start;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .team-listing,
      .team-text {
        grid-template-columns: 0.95fr 1fr;
      }
    }

    @include media-breakpoint-down(sm) {
      .team-listing {
        > div {
          margin: auto;
          max-width: 350px;

          &:nth-child(odd) {
            margin-left: 0;
          }

          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }
    }

    .team-text {
      margin-top: 0rem;

      .team-listing-text {
        margin-top: 0rem;
        grid-column: 2;
      }

      @include media-breakpoint-up(md) {
        min-height: $teamTopSpacing;
        margin-bottom: -$teamVerticalOffset;
      }

      @include media-breakpoint-down(md) {
        .team-listing-text {
          grid-column: 1 / span 2;
          padding-bottom: 3rem;
        }
      }
    }
  }

  .about-grey-bg {
    height: 62rem;
    z-index: -1;
    background-color: $oh-light-grey;
    position: absolute;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:first-child {
      left: 0%;
      width: calc(42vw - 5.8rem);
      top: 5rem;
    }

    &:nth-child(2),
    &:nth-child(4) {
      height: 42rem;
      right: 0%;
      width: calc(50vw - 5.8rem);
    }

    &:nth-child(3) {
      top: 167rem;
      height: 72rem;
      width: calc(73vw - 5.8rem);
    }

    &:nth-child(2) {
      top: 105rem;
    }

    &:nth-child(4) {
      top: 270rem;
    }
  }
}
