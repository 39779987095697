// Custom global variables
// NOTE: all custom variables should be prefixed with "oh-"
// to distinguish them from Bootstrap and avoid naming collisions
$oh-lightest-grey: #f9f9f9;
$oh-light-grey: #f3f3f3;
$oh-medium-grey: #555;
$oh-dark-grey: #212121;

$oh-orange: #ff5b16;

// ----- container width and padding for pulsar container mixins
$oh-container-padding-default: 20px;
$oh-container-width-wide: 1423px;
$oh-container-width-narrow: 1180px;

// bootstrap variable overrides
@import 'bootstrap/variables';

// NOTE: HTML smooth scrolling messes with other functionality
// like anchor tags, form validation, tabbing through links, etc.
$enable-smooth-scroll: false;

@import '~bootstrap/scss/variables';
