header {
  @include pulsar-container($oh-container-width-wide);
  padding-top: 3rem;
  padding-bottom: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem 2rem;

  z-index: 5;

  .site-logo {
    z-index: 10;

    img {
      min-width: 47px;
    }
  }

  .header-content-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem 5rem;
    flex-wrap: wrap;
  }

  .header-ctas {
    display: flex;
    gap: 0.75rem;

    .btn {
      margin: 0;
      text-transform: uppercase;

      &.btn-secondary {
        color: white;
      }
    }
  }

  .nav-button-close,
  .nav-button-open {
    display: inline-block;
  }

  .mobile-nav-header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 3rem;

    .header-content-inner {
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-between(md, lg) {
    align-items: center;

    .header-content-inner {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }

  @include media-breakpoint-up(md) {
    .mobile-nav {
      display: none;
    }

    .bg-text-decoration {
      display: none;
    }

    .mobile-nav-header {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 1rem;

    .site-logo {
      img {
        max-width: 47px;
      }
    }

    .header-ctas {
      margin-top: auto;
      padding: 1rem;
      flex-wrap: wrap;
      gap: 1rem;

      .btn {
        padding: 16px 20px;
        font-size: 1rem;
      }

      .btn-secondary {
        border-color: white;
      }
    }

    .header-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      pointer-events: none;
    }

    .header-content-inner {
      position: absolute;
      top: 0;
      left: 0;
      @include backdrop--secondary;
      width: 100%;
      height: 100%;
      gap: 0rem;

      display: grid;
      justify-content: unset;
      align-items: unset;
      grid-template-rows: auto 1fr;

      transform: translateX(100%);
    }

    .main-nav {
      position: relative;
      padding: 1rem;
      display: grid;
      grid-template-rows: 2fr auto 1fr;
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .bg-text-decoration {
        span {
          white-space: nowrap;
        }
      }

      ul {
        grid-row: 2;
        flex-direction: column;
        font-family: $headings-font-family;
        height: fit-content;
        margin: auto 0px;
        gap: clamp(1em, 4vh, 2.5em);

        li {
          font-size: clamp(1rem, 8vh, 3.5625rem);
          padding: 0;
          line-height: 1em;

          a {
            padding: 0;
            text-transform: none;
            color: white;

            @include interact {
              color: white;
            }

            &:after {
              width: 105%;
            }
          }
        }
      }
    }

    .nav-close {
      .header-content-inner {
        animation-name: slide-out;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
      }
    }

    .nav-open {
      pointer-events: all;

      .header-content-inner {
        animation-name: slide-in;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;

        .main-nav,
        .header-ctas {
          opacity: 0;
          animation-name: fade-in;
          animation-delay: 0.3s;
          animation-duration: 0.3s;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
        }
      }
    }
  }

  @include media-breakpoint-down(ss) {
    .header-ctas {
      gap: 0.5rem;

      .btn {
        padding: 16px 0px;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex: 0.9;
        }

        &:nth-child(2) {
          flex: 1.1;
        }
      }
    }
  }
}

//------------------------------------ STYLE FOR FOOTER ---------------------------------------//
$desktop-footer-cols: 5;
$mobile-footer-cols: 2;

$desktop-footer-inner-cols: 3;

footer {
  a {
    img {
      transition: all 0.5s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  @include make-row(0); // no gutter
  border-top: 1px solid black;
  border-left: 1px solid black;

  .footer-col {
    @include make-col-ready();
    display: flex;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: rfs-fluid-value(7rem) 0rem;
    padding: clamp(32px, 6vw, 115px) 0rem;
    overflow: hidden;

    @include media-breakpoint-up(xxl) {
      min-height: 432px;
    }

    .btn {
      padding: 0.6rem 1.4rem;
    }

    .footer-col-inner {
      margin: auto;
      width: calc(100% - $grid-gutter-width);
      width: calc(
        100% - clamp($grid-gutter-width, 6vw, $grid-gutter-width * 3)
      );
    }

    h2,
    h3,
    h4,
    p {
      font-family: $font-family-base;
    }

    h2,
    h3,
    h4 {
      font-weight: 700;
    }

    a:not(.btn-arrow-long) {
      position: relative;
      &:after {
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -2px;
        background-color: $primary;
        content: ' ';
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.3s;
      }
      @include interact {
        color: $primary;

        &:after {
          transform: scaleX(1);
          transition-delay: 0.3s;
        }
      }
    }

    .btn {
      margin-top: 0px;

      @include media-breakpoint-down(sm) {
        &.btn-arrow-long {
          &:after {
            content: none;
          }
        }
      }
    }

    p {
      @include font-size(16px);
      margin-bottom: 5px;
    }

    h2 {
      letter-spacing: -1.5px;
      font-size: 3rem;
      margin-bottom: rfs-fluid-value(2rem);

      @include media-breakpoint-down(xl) {
        font-size: 2.85rem;
      }
    }

    h3 {
      margin-bottom: 10px;
    }

    h4 {
      margin-bottom: 20px;
    }

    p {
      @include font-size(16px);
      margin: 10px 0px;

      a {
        text-decoration: none;
      }
    }
  }

  #footer-col__1,
  #footer-col__4 {
    @include make-col(3, $desktop-footer-cols);

    .footer-col-inner,
    .footer-col-inner.footer-service {
      margin: auto;

      @include media-breakpoint-up(xxl) {
        margin-right: rfs-fluid-value(7rem);
        margin-right: clamp(20px, 7.56vw, 254px);
      }

      @include media-breakpoint-up(xxxl) {
        max-width: 820px;
      }

      @include media-breakpoint-between(xxl, xxxl) {
        max-width: 710px;
      }

      @include media-breakpoint-between(xl, xxl) {
        max-width: 666px;
      }
    }

    @include media-breakpoint-down(lg) {
      @include make-col(2, $mobile-footer-cols);
    }
  }

  #footer-col__1 {
    @include media-breakpoint-down(lg) {
      padding-top: 4rem;
      padding-bottom: 4.8rem;
    }
  }

  #footer-col__2,
  #footer-col__3 {
    @include make-col(1, $desktop-footer-cols);

    @include media-breakpoint-down(lg) {
      @include make-col(1, $mobile-footer-cols);
    }

    @include media-breakpoint-up(lg) {
      .footer-col-inner {
        width: fit-content;
      }
    }
  }

  #footer-col__3 {
    a:not(.btn-arrow-long) {
      text-decoration: none;
      &:after {
        content: none;
      }
      span {
        display: block;
        position: relative;
        margin: 0px;
        width: fit-content;

        &:last-child {
          margin-bottom: 10px;
          &:after {
            transition-delay: 0.1s;
            transition-property: all;
          }
        }

        &:after {
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 3px;
          background-color: $primary;
          content: ' ';
          transform: scaleX(0);
          transform-origin: left;
          transition: all 0.3s;
        }
      }
      @include interact {
        span {
          &:after {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  #footer-col__4 {
    .footer-service {
      @include make-row(0);
      justify-content: space-between;

      p {
        font-weight: 600;
      }

      > [class^='footer-service'] {
        @include make-col-ready();
        @include make-col(1, $desktop-footer-inner-cols);

        @include media-breakpoint-between(lg, xxl) {
          @include make-col(1, 2);
          margin-bottom: 1rem;
        }
      }

      .footer-service-mid {
        h4 {
          margin-bottom: 2.6rem;
        }
      }

      .footer-service-communicate {
        display: flex;

        h4 {
          margin-right: 2rem;

          a {
            &:after {
              content: none;
            }

            @include interact {
              color: $primary;
            }
          }
        }

        p {
          @include font-size(16px);
          line-height: 1.833em;
        }
      }

      @include media-breakpoint-down(md) {
        .footer-service-left,
        .footer-service-mid,
        .footer-service-communicate {
          display: none;
        }

        .footer-service-right {
          width: 100%;
        }
      }
    }
  }

  #footer-col__5 {
    @include make-col(2, $desktop-footer-cols);

    .footer-col-inner {
      @include media-breakpoint-up(lg) {
        max-width: 620px;
      }
    }

    p {
      @include font-size(16px);
      line-height: 1.833em;

      @include media-breakpoint-down(sm) {
        @include font-size(14px);
      }
    }

    .footer-copyright {
      margin-top: rfs-fluid-value(7.5rem);
      margin-top: clamp(3.5rem, 8vw, 7.8rem);
      margin-bottom: -3.56rem;
      display: grid;
      grid-template-columns: 83px 56px auto;
      gap: 1.5rem;
      align-items: center;

      .ohmedia-logo,
      .phoenix-group-logo {
        &:after {
          content: none;
        }
      }

      @include media-breakpoint-down(xl) {
        margin-bottom: 0rem;
      }

      p {
        @include font-size(12px);
        line-height: 1.5em;
      }
    }

    @include media-breakpoint-down(lg) {
      @include make-col(2, $mobile-footer-cols);
    }
  }
}

//------------------------------------ STYLE FOR FEATURE BANNER ---------------------------------------//
.feature-banner {
  position: relative;
  padding-bottom: 3rem;

  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    h1 {
      margin-top: 1rem;
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }

  @include media-breakpoint-down(md) {
    &.feature-banner-home {
      padding-bottom: clamp(108px + 3rem, 36vw + 3rem, 216px + 3rem);

      .no-horizontal-scroll {
        bottom: clamp(-192px, -32vw, -96px);
      }
    }
  }

  &.small-banner {
    min-height: 175px;

    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: -12.5rem;
    width: 65%;
    right: 0;
    bottom: 0;
    background-color: $oh-light-grey;
    z-index: -2;

    @include media-breakpoint-up(xxl) {
      width: calc(50vw + 230px);
    }

    @include media-breakpoint-between(md, xl) {
      top: -14rem;
    }
  }

  [class^='banner-img'] {
    position: absolute;
    bottom: 0;
  }

  .banner-img-btm {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: clamp(270px, 90vw, 540px);
    }
  }

  .banner-img-right {
    right: 0;
    width: 413px;
    max-width: 33%;

    @include media-breakpoint-down(md) {
      opacity: 0;
      display: none;
    }
  }

  .banner-img-left {
    left: 0;
    width: 339px;

    @include media-breakpoint-down(xl) {
      opacity: 0;
      display: none;
    }
  }

  .feature-banner-inner {
    @include pulsar-container(939px);
  }

  .feature-banner-privacy {
    @include pulsar-container(1060px);
  }
}

.section-title-banner {
  .section-title-banner-inner {
    @include backdrop--secondary;
    background-color: transparent;
    position: relative;
    display: grid;

    &:before {
      content: '';
      position: absolute;
      background-color: $secondary;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .bg-text-decoration {
    grid-column: 1 / span 4;
    grid-row: 3;
    margin-top: -4rem;
  }

  .section-title-copy {
    position: relative;

    h2 {
      margin: 0;
      position: relative;
      font-size: 2.8rem;
      width: fit-content;

      &::after {
        content: '';
        position: absolute;
        width: 0.364em;
        height: 0.5em;
        background-image: url('/assets/frontend/images/icons/arrow-down-orange.svg');
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 0.3em;
        right: -0.9em;
      }
    }
  }

  .banner-image {
    position: relative;

    &:before {
      content: '';
      height: 100%;
      width: calc(100% - 100px);
      position: absolute;
      z-index: -1;
      left: 100px;
      top: 0;
      background-color: $oh-light-grey;
    }
  }

  @include media-breakpoint-up(md) {
    .section-title-banner-inner {
      grid-template-columns:
        minmax($oh-container-padding-default, 1fr) minmax(auto, 242px)
        minmax(auto, 939px) minmax($oh-container-padding-default, 1fr);

      grid-template-rows: 0.65fr 1fr 0.85fr;

      &:before {
        grid-row: 2;
      }
    }

    .section-title-copy {
      grid-column: 2;
      grid-row: 2;

      display: flex;
      align-items: flex-end;
    }

    .banner-image {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;
    }
  }

  @include media-breakpoint-down(md) {
    .section-title-banner-inner {
      grid-template-rows: repeat(2, 1fr) auto;

      &:before {
        grid-row: 2;
      }
    }

    .section-title-copy {
      display: flex;
      align-items: flex-end;
      padding: 40px 0;
      padding-left: 20px;
    }

    .banner-image {
      grid-row: 1 / span 2;
      padding-left: 20px;
    }
  }
}

//--------------------------------- testimonial STYLE ------------------------------//
section.testimonial {
  position: relative;
  @include backdrop--secondary;
  padding-top: 5.45rem;
  padding-top: clamp(3.8875rem, calc(2.18vw + 3.356rem), 5.45rem);
  padding-bottom: 8.125rem;
  padding-bottom: clamp(5.625rem, calc(3.5vw + 4.77rem), 8.125rem);

  .bg-text-decoration {
    white-space: nowrap;
  }

  @include media-breakpoint-up(md) {
    .bg-text-decoration {
      top: 40%;
      transform: translateY(-50%);
    }
  }
}

.testimonial-container {
  position: relative;
  display: flex;
  width: 90%;
  max-width: 936px;
  margin: auto;

  .testimonial-container-text {
    padding-top: 70px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  > img {
    margin-right: 40px;
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
    object-position: center;
  }

  .quote-content {
    font-family: $headings-font-family;
    position: relative;
    @include font-size(28px);
    line-height: 1.25em;
    font-weight: 700;
    margin-bottom: 20px;

    &::before {
      content: url('/assets/frontend/images/icons/quote-icon.svg');
      position: absolute;
      bottom: 105%;
      left: 0%;
    }
  }

  .quote-person {
    @include font-size(18px);
    font-weight: 700;
    margin-bottom: 0px;
  }

  .quote-position {
    @include font-size(16px);
    margin-bottom: 0px;
  }
}

// Bootstrap's modal doesn't dynamically work with $grid-breakpoints
// There is no .modal-xxl or .modal-xxxl, so we're faking it

@include media-breakpoint-up(xxl) {
  $modal-xxl: map-get($grid-breakpoints, 'xxl') - 60px;

  .modal-xl {
    --#{$prefix}modal-width: #{$modal-xxl};
  }
}

@include media-breakpoint-up(xxxl) {
  $modal-xxxl: map-get($grid-breakpoints, 'xxxl') - 60px;

  .modal-xl {
    --#{$prefix}modal-width: #{$modal-xxxl};
  }
}

.no-horizontal-scroll {
  overflow-x: hidden;
}
