@import 'fonts.scss';

@import '~bootstrap/scss/functions';

@import 'variables';

/**
 * Useful mixins:
 *
 *   nodes_modules/bootstrap/scss/mixins/_border-radius.scss
 *   nodes_modules/bootstrap/scss/mixins/_breakpoints.scss
 *   clearfix()
 *   img-fluid()
 *   list-unstyled()
 *   reset-text()
 *   text-truncate()
 *   visually-hidden()
 *   visually-hidden-focusable()
 *   RFS (see bottom of nodes_modules/bootstrap/scss/vendor/_rfs.scss)
 */
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';

// https://getbootstrap.com/docs/5.2/layout/containers/
@import '~bootstrap/scss/containers';

// Only 1 of these grids should be enabled

// https://getbootstrap.com/docs/5.2/layout/grid/
$enable-grid-classes: true;

// They say their CSS Grid is experimental and opt-in
// https://getbootstrap.com/docs/5.2/layout/css-grid/
$enable-cssgrid: not $enable-grid-classes;

//form styles, (the form will be created in HubSpot and likely styled in HubSpot)
//@import "~bootstrap/scss/forms";

@import '~bootstrap/scss/grid';

// https://getbootstrap.com/docs/5.2/components/buttons/
@import '~bootstrap/scss/buttons';

// https://getbootstrap.com/docs/5.2/components/close-button/
@import '~bootstrap/scss/close';

/**
 * Includes various classes for consistency:
 * https://getbootstrap.com/docs/5.2/utilities/spacing/
 *
 * This is so everyone's spacing can be based off of the $spacer variables
 * without having to actually work directly with those variables.
 *
 * These classes can be applied in HTML or extended in Sass
 */
@import 'bootstrap/utilities';

// For https://getbootstrap.com/docs/5.2/components/collapse/ functionality
@import '~bootstrap/scss/transitions';

@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/tables';

@import '~aos/src/sass/aos';

/**
 * RULES:
 * 1. Only use the font-size() mixin. Never set the font-size property manually.
 * 2. Never set any margin/padding properties manually. Instead:
 *   a. If the margin/padding is relative to the $spacer variables,
 *      use the utility classes.
 *   b. Otherwise, use the mixins from RFS.
 */

@import 'mixins';
@import 'global';
@import 'pages';
