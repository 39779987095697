section.bulk-content {
  padding-top: 2.5em;
  padding-bottom: 5em;
  @include pulsar-container(1060px);

  li {
    margin-bottom: 1rem;
  }

  h2,
  h3,
  h4 {
    margin-top: 1em;
  }

  h2 {
    @extend %oh-h3;
  }

  h3 {
    @extend %oh-h4;
  }

  h4 {
    @extend %oh-h5;
  }
}

.center-content {
  text-align: center;

  img {
    margin-right: auto;
    margin-left: auto;
  }
}
