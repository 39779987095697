section[class^='services-'] {
  position: relative;
  .bg-text-decoration {
    top: 4rem;
  }
  &:nth-of-type(odd) {
    @include backdrop--secondary;

    .bg-text-decoration {
      top: unset;
      bottom: 5rem;
    }
  }

  &.services-ursite {
    .bg-text-decoration {
      top: 7rem;
      bottom: unset;
    }
  }

  ul.dash-style {
    li {
      @include font-size(18px);
      line-height: 1.793em;
      margin-top: 25px;

      @include media-breakpoint-down(md) {
        @include font-size(16px);
      }
    }
  }
}

.feature-banner.feature-banner-services {
  @include oh-feature-banner-common;

  .feature-banner-inner {
    @include media-breakpoint-up(sm) {
      margin-bottom: 10rem;
      min-height: 25rem;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 71vw;
  }

  .banner-img-btm {
    bottom: 0;
  }
}

.services-block-outer {
  position: relative;
  @include pulsar-container($oh-container-width-wide);

  .services-block-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @include media-breakpoint-down(md) {
      flex-direction: column;

      .services-copy {
        margin-left: 0px;
        margin-right: auto;
      }

      .photo-collage {
        width: 70%;
        min-width: 240px;
        margin-left: auto;
      }
    }

    @include media-breakpoint-down(sm) {
      .photo-collage {
        width: 85%;
      }
    }
  }

  .services-copy {
    margin: auto;

    h2 {
      margin-top: 1em;
    }

    @include media-breakpoint-up(sm) {
      min-width: 350px;
    }
  }

  .photo-collage {
    display: grid;
    gap: 18px;
    width: 100%;

    > div.logo {
      filter: grayscale(100%);
      position: relative;
      background-color: $oh-lightest-grey;
      margin-top: auto;
      height: 0px;
      width: 100%;
      padding-bottom: 100%;

      img {
        width: 60%;
        height: 40%;
        object-fit: contain;
        object-position: center;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

section.services-design-ux {
  position: relative;

  .services-block-outer {
    .services-block-inner {
      max-width: 934px;
      margin-right: auto;
    }

    .services-copy {
      margin-right: 0px;
    }

    .photo-collage {
      max-width: 388px;
      grid-template-columns: 0.75fr 1fr;

      > div {
        &:nth-child(1) {
          grid-column: span 2;
          max-width: 352px;
          width: 90%;
        }

        &:nth-child(3) {
          margin-top: 0px;
        }
      }
    }
  }
}

section.services-develop {
  .services-block-outer {
    .services-block-inner {
      max-width: 1300px;
      margin-left: auto;
    }

    .services-copy {
      margin-left: 0px;
      width: 62.7%;
      max-width: 816px;

      @include media-breakpoint-down(sm) {
        width: 95%;
      }
    }

    .services-develop-list {
      display: grid;
      grid-template-columns: 51% 41%;
      justify-content: space-between;

      @include media-breakpoint-down(xl) {
        grid-template-columns: 1fr;

        .dash-style {
          margin-bottom: 0px;
        }
      }
    }

    .photo-collage {
      max-width: 442px;
      grid-template-columns: 1fr 1fr;

      @include media-breakpoint-down(md) {
        padding-top: 10%;
      }

      > div {
        &:nth-child(odd) {
          transform: translateY(-25%);
        }

        &:nth-child(even) {
          transform: translateY(25%);
        }
      }
    }
  }
}

section.services-branding,
section.services-seo {
  .services-block-outer {
    @include pulsar-container($oh-container-width-narrow);

    .services-block-inner {
      max-width: 992px;
      margin-right: auto;
    }

    .services-copy {
      margin-right: 0px;
    }
  }
}

section.services-branding {
  .photo-collage {
    max-width: 454px;
    grid-template-columns: 0.95fr 1fr;

    > div {
      &:nth-child(3) {
        transform: translateX(57%);
      }
    }
  }
}

section.services-accessibility {
  .services-block-outer {
    .services-block-inner {
      max-width: 1060px;
      margin-left: auto;
    }

    .services-copy {
      margin-left: 0px;
    }
  }
}

section.services-accessibility,
section.services-seo {
  .photo-collage {
    > div {
      &:nth-child(1) {
        grid-row: 2;
        transform: translateY(-60%);
      }

      &:nth-child(2) {
        grid-column: 2;
      }

      &:nth-child(3) {
        grid-row: 2;
      }
    }
  }
}

section.services-accessibility {
  .photo-collage {
    max-width: 494px;
    grid-template-columns: 0.8fr 1fr;

    > div {
      &:nth-child(3) {
        width: 80%;
        padding-bottom: 80%;
      }
    }
  }
}

section.services-seo {
  .photo-collage {
    max-width: 486px;
    grid-template-columns: 0.83fr 1fr;

    > div {
      &:nth-child(3) {
        width: 83%;
        padding-bottom: 83%;
      }
    }
  }
}

section.services-ursite {
  .services-block-outer {
    .services-block-inner {
      max-width: 510px;
      margin: auto;

      h2 {
        line-height: 1.106em;
      }

      p {
        @include font-size(16px);
        margin: 40px 0px 60px;
        letter-spacing: 0.01em;
        line-height: 1.833em;
      }
    }
  }
}

.services-design-ux,
.services-branding,
.services-seo {
  .services-block-inner {
    padding-top: 120px;
    padding-bottom: 180px;
  }
}

.services-develop,
.services-accessibility,
.services-ursite {
  .services-block-inner {
    padding-top: 160px;
    padding-bottom: 180px;
  }
}

@include media-breakpoint-down(md) {
  .services-design-ux,
  .services-branding,
  .services-seo {
    .services-block-inner {
      padding-top: 20px;
      padding-bottom: 100px;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .services-develop,
  .services-accessibility,
  .services-ursite {
    .services-block-inner {
      padding-top: 20px;
      padding-bottom: 70px;
    }
    .photo-collage {
      margin-top: 30px;
      margin-left: auto;
      margin-bottom: 20px;
    }
  }
}
